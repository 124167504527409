

import {Component, Vue} from "vue-property-decorator";
import TakipSorgulaFormWizard from "@/components/forms/form-wizard/eicrapro/TakipSorgulaFormWizard.vue";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, TakipSorgulaFormWizard}
})
export default class TakipSorgulaView extends Vue {
  taskTuru = TaskTuru;
}
